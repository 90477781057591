import React, { useState, useEffect } from 'react';
import './VehiculosContainer.css';
import useAuth from '../hooks/useAuth';
import editIcon from '../assets/editar.png'; // Importa el ícono al inicio del archivo
import cancelIcon from '../assets/cancelar.png'; // Importa el ícono al inicio del archivo
import saveIcon from '../assets/Guardar.png'; // Importa el ícono al inicio del archivo

const ReporteVehiculos = () => {

  const [vehiculos, setVehiculos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null); // Para saber qué fila se está editando
  const [editedVehiculo, setEditedVehiculo] = useState({}); // Para guardar los cambios
  const [message, setMessage] = useState(''); // Estado para guardar el mensaje


  const marcas = [
    'AKT', 'AUDI', 'BAJAJ', 'BMW', 'CHEVROLET', 'CHERY', 'CHINA', 'CITROEN', 'FIAT',
    'FORD', 'HARLEY', 'HONDA', 'HYUNDAI', 'KAWASAKI', 'KIA', 'MAZDA', 'MERCEDES-BENZ', 
    'MITSUBISHI', 'NISSAN', 'PEUGEOT', 'RAM', 'RENAULT', 'ROYALE ENFIELD', 'SUZUKI', 
    'TOYOTA', 'VOLKSWAGEN', 'YAMAHA', 'XIAOMI', 'NIU', 'SEGWAY', 'URB-E', 'INMOTION', 
    'TRIUMPH', 'TRONX', 'SMOOS', 'E-TWOW'
  ];

  const colores = [
    'AMARILLO', 'AZUL', 'BEIGE', 'BLANCO', 'DORADO', 'GRIS', 'MARRÓN', 'NEGRO', 
    'NARANJA', 'PLATA', 'PLATEADO', 'ROJO', 'ROSA', 'TURQUESA', 'VIOLETA', 'VERDE'
  ];

  const tipo = [
    'CARRO', 'MOTO', 'CAMIONETA', 'BICICLETA', 'PATINETA ELECTRICA'
  ];


  const modelos = Array.from({ length: 105 }, (_, i) => (2025 - i).toString()); // Modelos desde 2025 hasta 1920.



  const [filters, setFilters] = useState({
    placa: '',
    cedula: '',
    tipo: '',
    noparqueadero: '',
  });

  // FILTRA LOS VEHICULOS EN LA REJILLA DE ACUERDO A LOS TEXT
  const filteredVehiculos = vehiculos.filter((vehiculo) => {
    return (
      (vehiculo.PLACA_VEHICULO || '').toLowerCase().includes(filters.placa.toLowerCase()) &&
      (vehiculo.DOCUMENTO_IDENTIDAD || '').toLowerCase().includes(filters.cedula.toLowerCase()) &&
      (vehiculo.TIPO_VEHICULO || '').toLowerCase().includes(filters.tipo.toLowerCase()) &&
      (vehiculo.NUMERO_PARQUEADERO || '').toLowerCase().includes(filters.noparqueadero.toLowerCase())
    );
  });

 
  useEffect(() => {

    const nitFromStorage = localStorage.getItem('nit'); // Usar nit desde localStorage

    /*CONSULTA EN EL BACKEND LOS AUTOMOVILES QUE HAY ASOCIADOS A UN NIT*/
    const fetchVehiculos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_VEHICULO_ENDPOINT}/${nitFromStorage}`);
        const data = await response.json();
        if (response.ok) {
          setVehiculos(data);
        }
      } catch (error) {
        console.error('ERROR CARGANDO LOS VEHICULOS', error);
      } finally {
        setLoading(false);
      }
    };fetchVehiculos();
  }, []);

  // Actualizar filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Activar modo edición
  const handleEdit = (index) => {
    setMessage('EDITANDO...');
    setEditingIndex(index);
    const vehiculo = filteredVehiculos[index];
    const fechaSoat = vehiculo.FECHA_SOAT_FIN ? new Date(vehiculo.FECHA_SOAT_FIN).toISOString().split('T')[0] : '';
    setEditedVehiculo({ 
      ...vehiculo, 
      FECHA_SOAT_FIN: fechaSoat,
    });
  };

  

   // Guardar cambios
  const handleSave = async () => {
    try {

      setMessage('GRABANDO...');
      const nitStorage = localStorage.getItem('nit'); // Usar nit desde localStorage
      
      const tipoVehiculoMap = {
        CARRO: 1,
        MOTO: 2,
        CAMIONETA: 3,
        BICICLETA: 4,
        "PATINETA ELECTRICA": 5,
      };




      const dataToSend = {
        placa: editedVehiculo.PLACA_VEHICULO,
        marca: editedVehiculo.MARCA_VEHICULO,
        modelo: editedVehiculo.MODELO_VEHICULO,
        linea: editedVehiculo.LINEA_VEHICULO,
        color: editedVehiculo.COLOR_VEHICULO,
        cedula: editedVehiculo.DOCUMENTO_IDENTIDAD,
        nit: nitStorage,
        idTipoVehiculo: tipoVehiculoMap[editedVehiculo.TIPO_VEHICULO] || null, // Transforma el tipo a su ID correspondiente
        fechaFinSoat: editedVehiculo.FECHA_SOAT_FIN,
        noParqueadero: editedVehiculo.NUMERO_PARQUEADERO,
      };

      const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_EDIT_CAR}`;
      console.log('Fetch URL:', url); // Esto imprimirá la URL en la consola
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_EDIT_CAR}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const result = await response.json(); 
 
        // Actualizar estado local con los datos editados
        const updatedVehiculos = [...vehiculos];
        updatedVehiculos[editingIndex] = editedVehiculo;

        setVehiculos(updatedVehiculos);
        setEditingIndex(null);
        setEditedVehiculo({});
        setMessage(result.message); 
      } else {
        const result = await response.json(); // Aseguramos que 'result' esté definido en este contexto
        setMessage(result.message); 
      }
    } catch (error) {
      setMessage(error.message); 
    }
  };

  // Cancelar edición
  const handleCancel = () => {
    setMessage('');
    setEditingIndex(null);
    setEditedVehiculo({});
  };


  // Manejar cambios en los campos editables
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVehiculo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  return (
    <div>
      <h2>Listado de Vehículos</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          {/* Filtros de búsqueda */}
          <div className="filters">
            <input
              className="input-field"
              type="text"
              name="placa"
              placeholder="Filtrar por Placa"
              value={filters.placa}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="text"
              name="cedula"
              placeholder="Filtrar por Cédula"
              value={filters.cedula}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="text"
              name="tipo"
              placeholder="Filtrar por Tipo"
              value={filters.tipo}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="numeric"
              name="noparqueadero"
              placeholder="Filtrar por Parqueadero"
              value={filters.noparqueadero}
              onChange={handleFilterChange}
            />
          </div>

          {/* Tabla de vehículos */}
          
          <table>
            <thead>
              <tr>
                <th>PLACA</th>
                <th>CEDULA</th>
                <th>NOMBRES</th>
                <th>APELLIDOS</th>
                <th>MARCA</th>
                <th>LINEA</th>
                <th>MODELO</th>
                <th>TIPO</th>
                <th>COLOR</th>
                <th># </th>
                <th>VENCIMIENTO SOAT</th>
                <th>ACTIVO</th>
                <th></th> {/* Columna para los botones */}
              </tr>
            </thead>
            <tbody>
              {filteredVehiculos.map((vehiculo, index) => (
                <tr key={index}>
                  <td>{vehiculo.PLACA_VEHICULO}</td>
                  <td>{vehiculo.DOCUMENTO_IDENTIDAD}</td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="NOMBRE"
                        value={editedVehiculo.NOMBRE || ''}
                        onChange={handleInputChange}
                        style={{ textTransform: 'uppercase' }} // Aquí se asegura que el texto se muestre en mayúsculas

                      />
                    ) : (
                      vehiculo.NOMBRE
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="APELLIDOS"
                        value={editedVehiculo.APELLIDOS || ''}
                        onChange={handleInputChange}
                        style={{ textTransform: 'uppercase' }} // Aquí se asegura que el texto se muestre en mayúsculas
                      />
                    ) : (
                      vehiculo.APELLIDOS
                    )}
                  </td>
 
                  <td>
                    {editingIndex === index ? (
                      <select
                        name="MARCA_VEHICULO"
                        value={editedVehiculo.MARCA_VEHICULO || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona una Marca</option>
                        {marcas.map((marca, idx) => (
                          <option key={idx} value={marca}>
                            {marca}
                          </option>
                        ))}
                      </select>
                    ) : (
                      vehiculo.MARCA_VEHICULO
                    )}
                  </td>


                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="LINEA_VEHICULO"
                        value={editedVehiculo.LINEA_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.LINEA_VEHICULO
                    )}
                  </td>

                 <td>
                    {editingIndex === index ? (
                      <select
                        name="MODELO_VEHICULO"
                        value={editedVehiculo.MODELO_VEHICULO || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona un Modelo</option>
                        {modelos.map((modelo, idx) => (
                          <option key={idx} value={modelo}>
                            {modelo}
                          </option>
                        ))}
                      </select>
                    ) : (
                      vehiculo.MODELO_VEHICULO
                    )}
                  </td>


     
                   <td>
                    {editingIndex === index ? (
                      <select
                        name="TIPO_VEHICULO"
                        value={editedVehiculo.TIPO_VEHICULO || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Seleccionar Tipo de Vehículo</option>
                        {tipo.map((tipoVehiculo, idx) => (
                          <option key={idx} value={tipoVehiculo}>
                            {tipoVehiculo}
                          </option>
                        ))}
                      </select>
                    ) : (
                      vehiculo.TIPO_VEHICULO
                    )}
                  </td>



                 <td>
                  {editingIndex === index ? (
                    <select
                      name="COLOR_VEHICULO"
                      value={editedVehiculo.COLOR_VEHICULO || ''}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecciona un Color</option>
                      {colores.map((color, idx) => (
                        <option key={idx} value={color}>
                          {color}
                        </option>
                      ))}
                    </select>
                  ) : (
                    vehiculo.COLOR_VEHICULO
                  )}
                </td>

                  <td>
                    {editingIndex === index ? (
                      <input
                        name="NUMERO_PARQUEADERO"
                        value={editedVehiculo.NUMERO_PARQUEADERO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.NUMERO_PARQUEADERO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="date"
                        name="FECHA_SOAT_FIN"
                        value={editedVehiculo.FECHA_SOAT_FIN || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.FECHA_SOAT_FIN
                    )}
                  </td>
                  <td>{vehiculo.VIGENTE ? 'Sí' : 'No'}</td>
                  <td>
                    {editingIndex === index ? (
                      <>
                        <img
                          src={saveIcon}
                          alt="Grabar"
                          className="icon-button"
                          onClick={() => handleSave(index)}
                        />
                        <img
                          src={cancelIcon}
                          alt="Cancelar"
                          className="icon-button"
                          onClick={() => handleCancel(index)}
                        />
                      </>
                    ) : (
                      <img
                        src={editIcon}
                        alt="Editar"
                        className="icon-button"
                        onClick={() => handleEdit(index)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                    {/* Mostrar el mensaje debajo de la tabla */}
                    {message && <p className="message-label">{message}</p>}
         </>
      )}
    </div>
  );
};


export default ReporteVehiculos;
