import React, { useState } from 'react';
import '../App.css'; // Asegúrate de importar los estilos globales

const RegistroConjunto = () => {
  const [cedulaAdm, setCedulaAdm] = useState('');
  const [nombresAdm, setNombresAdm] = useState('');
  const [apellidosAdm, setApellidosAdm] = useState('');
  const [telefonoAdmin, setTelefonoAdmin] = useState('');  // Nuevo campo para teléfono del administrador
  const [correoAdm, setCorreoAdm] = useState('');
  const [clave, setClave] = useState('');  // Nuevo campo para contraseña
  const [nit, setNit] = useState('');
  const [conjunto, setConjunto] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [emailConjunto, setEmailConjunto] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [message, setMessage] = useState('');  // Declaramos el estado para el mensaje
  const [showPassword, setShowPassword] = useState(false);  // Estado para controlar la visibilidad de la contraseña
  const [isAdminFound, setIsAdminFound] = useState(false);



  // Lista de ciudades con su número
  const ciudades = [
    { numero: '1', ciudad: 'BOGOTÁ' },
    { numero: '2', ciudad: 'MEDELLÍN' },
    { numero: '3', ciudad: 'CALI' },
    { numero: '4', ciudad: 'SANTA MARTA' },
    { numero: '5', ciudad: 'BARRANQUILLA' },
    { numero: '6', ciudad: 'CÚCUTA' },
    { numero: '7', ciudad: 'BUCARAMANGA' },
    { numero: '8', ciudad: 'PEREIRA' },
    { numero: '9', ciudad: 'CARTAGENA' },
    { numero: '10', ciudad: 'IBAGUÉ' },
    { numero: '11', ciudad: 'MANIZALES' },
    { numero: '12', ciudad: 'VILLAVICENCIO' },
    { numero: '13', ciudad: 'ARMENIA' },
    { numero: '14', ciudad: 'NEIVA' },
    { numero: '15', ciudad: 'MONTERÍA' }
  ].sort((a, b) => a.ciudad.localeCompare(b.ciudad)); // Ordenar solo aquí

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validar que el correo del administrador no sea el mismo que el correo del conjunto
      if (correoAdm === emailConjunto) {
        setMessage('EL CORREO DEL ADMINISTRADOR DEBE SER DIFERENTE AL DEL CONJUNTO.');
        return; // Detener el envío si los correos son iguales
      }

      // Validar que el teléfono del administrador no sea el mismo que el teléfono del conjunto
      if (telefonoAdmin === telefono) {
        setMessage('EL TELEFONO DEL ADMINISTRADOR DEBE SER DIFERENTE AL DEL CONJUNTO.');
        return; // Detener el envío si los teléfonos son iguales
      }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_CONJUNTO_ENDPOINT}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          docidentidad: cedulaAdm,
          nombre: nombresAdm,
          apellido: apellidosAdm,
          telefono: telefonoAdmin, // Mapear correctamente el teléfono del administrador
          email: correoAdm,
          clave, // La clave no necesita mapeo, ya coincide
          nit,
          conjunto,
          direccion,
          telefonoconjunto: telefono, // Mapear el teléfono del conjunto
          emailconjunto: emailConjunto,
          idciudad: ciudad // Mapear correctamente la ciudad
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);

        // Solo borrar los campos si el mensaje es "CONJUNTO REGISTRADO EXITOSAMENTE"
        if (data.message === 'CONJUNTO REGISTRADO EXITOSAMENTE') {
          setNit('');
          setConjunto('');
          setDireccion('');
          setCiudad('');
          setTelefono('');
          setEmailConjunto('');
          setCedulaAdm('');
          setNombresAdm('');
          setApellidosAdm('');
          setCorreoAdm('');
          setTelefonoAdmin('');  // Limpiar el campo de teléfono del administrador
          setClave('');  // Limpiar el campo de la clave*/
        }
      } else {
        setMessage(data.message || 'ERROR DESCONOCIDO');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('ERROR INESPERADO AL REGISTRAR EL CONJUNTO');
    }
  };



  const handleAdmin = async (e) => { 
    e.preventDefault();

    try {
       const url = `${process.env.REACT_APP_API_URL}/administrador?cedula=${cedulaAdm}`;


        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        // Verifica el tipo de contenido de la respuesta
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            const data = await response.json(); // Solo parseamos a JSON si es un JSON

            if (response.ok) {
                if (data.found) {
                    setMessage('EL ADMINISTRADOR YA ESTA REGISTRADO EN NUESTRA BD. PROCEDA A REGISTRAR EL CONJUNTO');
                    setIsAdminFound(true);
                    setNombresAdm('***');
                    setApellidosAdm('***');
                    setCorreoAdm('***');
                    setTelefonoAdmin('***');
                    setClave('***');
                }
            } else {
                setIsAdminFound(false);
                setMessage(data.message || 'ADMINISTRADOR NO REGISTRADO. COMPLETE LOS DATOS');
                setNombresAdm('');
                setApellidosAdm('');
                setCorreoAdm('');
                setTelefonoAdmin('');
                setClave('');
            }
        } else {
            // Si la respuesta no es JSON, mostramos el texto plano
            const text = await response.text();
            console.error('Respuesta no JSON:', text);
            setIsAdminFound(false);
            setMessage('NO SE PUDO OBTENER UN JSON VÁLIDO');
        }
    } catch (error) {
        console.error('Error:', error);
        setIsAdminFound(false);
        setMessage('NO HAY CONEXION CON EL SERVIDOR... ');
    }
};

  
  
  
  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="form-left">
        <h2>REGISTRO DE CONJUNTO</h2>

        {/* Campos existentes */}
        <div className="group">
          <label>NIT:</label>
          <input
            type="text"
            value={nit}
            onChange={(e) => {
              const value = e.target.value.trim();  // Eliminar espacios al principio y al final
              // Permitir solo números y un máximo de 10 caracteres
              if (/^\d*$/.test(value) && value.length <= 10) {
                setNit(value);
                setMessage('ESTADO: Digitando información...'); // Cambiar el mensaje
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el NIT del conjunto"
          />
        </div>

        <div className="group">
            <label>Conjunto:</label>
            <input
                type="text"
                value={conjunto}
                onChange={(e) => setConjunto(e.target.value.toUpperCase())} // Convertir a mayúsculas
                required
                className="input-field"
                placeholder="Ingresa el nombre del conjunto"
            />
        </div>

        <div className="group">
          <label>Dirección:</label>
          <input
            type="text"
            value={direccion}
            onChange={(e) => setDireccion(e.target.value.toUpperCase())} // Convertir a mayúsculas
            required
            className="input-field"
            placeholder="Ingresa la dirección del conjunto"
          />
        </div>

        <div className="group">
          <label>Ciudad:</label>
          <select 
            value={ciudad} 
            onChange={(e) => setCiudad(e.target.value)} 
            className="input-field"
            required>
            <option value="">Selecciona una ciudad</option>
            {ciudades.map((item) => (
              <option key={item.numero} value={item.numero}>
                {item.ciudad}
              </option>
            ))}
          </select>
        </div>

        <div className="group">
          <label>Teléfono:</label>
          <input
            type="text"
            value={telefono}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (/^\d*$/.test(value) && value.length <= 10) {
                setTelefono(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el teléfono del conjunto"
          />
        </div>

        <div className="group">
          <label>Email del conjunto:</label>
          <input
            type="email"
            value={emailConjunto}
            onChange={(e) => setEmailConjunto(e.target.value.trim().toLowerCase())}
            required
            className="input-field"
            placeholder="Ingresa el email del conjunto"
          />
        </div>

        <div className="group">
          <label>Cédula del administrador:</label>
          <input
            type="text"
            value={cedulaAdm}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (/^\d*$/.test(value) && value.length <= 10) {
                setCedulaAdm(value);
              }
            }}
            onBlur={handleAdmin}  // Llamar a handleAdmin cuando el input pierda el foco
            required
            className="input-field"
            placeholder="Ingresa la cédula del administrador"
          />
        </div>


 
        <div className="group">
           
          <label>Contraseña:</label>
         

          <div className="input-wrapper">
            <input
              type={showPassword ? 'text' : 'password'} // Alterna entre 'text' y 'password'
              value={clave}
              onChange={(e) => setClave(e.target.value.trim())}
              required
              className="input-field"
              placeholder="Ingresa la contraseña"
              disabled={isAdminFound}

            />
          </div>
        </div>
      
         {/* Toggler para mostrar/ocultar la contraseña */}
         <div className="toggler">
            <input
              id="toggler-1"
              name="toggler-1"
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)} // Alterna el estado de showPassword
            />
            <label htmlFor="toggler-1"></label> {/* Etiqueta del toggle */}
            
          </div>
       
         
          <div className="group">
  <label>Nombres del administrador:</label>
  <input
    type="text"
    value={nombresAdm}
    onChange={(e) => {
      const value = e.target.value.toUpperCase(); // Convertir a mayúsculas
      if (/^[A-Za-z\s]*$/.test(value)) {
        setNombresAdm(value);
      }
    }}
    required
    className="input-field"
    placeholder="Ingresa los nombres del administrador"
    disabled={isAdminFound}
  />
</div>

<div className="group">
  <label>Apellidos del administrador:</label>
  <input
    type="text"
    value={apellidosAdm}
    onChange={(e) => {
      const value = e.target.value.toUpperCase(); // Convertir a mayúsculas
      if (/^[A-Za-z\s]*$/.test(value)) {
        setApellidosAdm(value);
      }
    }}
    required
    className="input-field"
    placeholder="Ingresa los apellidos del administrador"
    disabled={isAdminFound}
  />
</div>

<div className="group">
  <label>Correo del administrador:</label>
  <input
    type="email"
    value={correoAdm}
    onChange={(e) => setCorreoAdm(e.target.value.trim().toLowerCase())}
    required
    className="input-field"
    placeholder="Ingresa el correo del administrador"
    disabled={isAdminFound}
  />
</div>

<div className="group">
  <label>Teléfono del administrador:</label>
  <input
    type="text"
    value={telefonoAdmin}
    onChange={(e) => {
      const value = e.target.value.trim();
      if (/^\d*$/.test(value) && value.length <= 10) {
        setTelefonoAdmin(value);
      }
    }}
    required
    className="input-field"
    placeholder="Ingresa el teléfono del administrador"
    disabled={isAdminFound}
  />
</div>


     
        

        {/* Mostrar el mensaje de respuesta del backend */}
        {message && <p className="message-label">{message}</p>}

        <button type="submit" className="btn-submit">
          Registrar Conjunto
        </button>
      </form>
    </div>
  );
};

export default RegistroConjunto;

