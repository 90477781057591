import React, { useState } from 'react';
import '../App.css'; // Asegúrate de importar los estilos globales

const PropietariosVehiculos = () => {
  const [documento, setDocumento] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [torre, setTorre] = useState('');
  const [apto, setApto] = useState('');
  const [message, setMessage] = useState('');  // Declaramos el estado para el mensaje


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let nit = localStorage.getItem('nit'); // Usar nit desde localStorage
    //console.log('nit en el insert vehiculo ',nit);

    try {
        const response = await fetch (`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_PROPIETARIO_ENDPOINT}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          documento,
          nombres,
          apellidos,
          telefono,
          email,
          torre,
          apto,
          nit,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage(data.message);
        
        // Solo borrar los campos si el mensaje es "PROPIETARIO INSERTADO CORRECTAMENTE"
        if (data.message === 'PROPIETARIO REGISTRADO EXITOSAMENTE') {
          setDocumento('');
          setNombres('');
          setApellidos('');
          setTelefono('');
          setEmail('');
          setTorre('');
          setApto('');
        }
      } else {
        setMessage(data.message || 'ERROR DESCONOCIDO');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('ERROR INESPERADO AL REGISTRAR EL PROPIETARIO');
    }
  };


  

  return (
    <div className="login-container">
      

      <form onSubmit={handleSubmit} className="form-left">
          {/* Título del formulario */}
          <h2>REGISTRO DE PROPIETARIOS</h2>

          <div className="group">
            <label>Cédula:</label>
            <input
              type="text"
              value={documento}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 10) { // Permite solo números y máximo 10 caracteres
                  setDocumento(value);
                  setMessage('ESTADO: Digitando información...'); // Cambiar el mensaje
                }
              }}
              required
              className="input-field"
              placeholder="Ingresa la cédula del propietario ej. 100254875"  // Mensaje antes de ingresar información
            />
          </div>


          <div className="group">
            <label>Nombres:</label>
            <input
              type="text"
              value={nombres}
              onChange={(e) => {
                const value = e.target.value.toUpperCase(); // Convierte el valor a mayúsculas
                // Permitir solo letras y espacios
                if (/^[A-Z\s]*$/.test(value)) {
                  setNombres(value); // Asigna el valor ingresado convertido
                }
              }}
              required
              className="input-field"
              placeholder="Ingresa los nombres del propietario ej. JUAN FRANCISCO" // Mensaje antes de ingresar información
            />
          </div>




        <div className="group">
          <label>Apellidos:</label>
          <input
            type="text"
            value={apellidos}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              // Solo permite letras y espacios
              if (/^[A-Za-z\s]*$/.test(value)) {
                setApellidos(value); 
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa los apellidos del propietario ej. RIOS PENAGOS "  // Mensaje antes de ingresar información
          />
        </div>




        <div className="group">
          <label>Teléfono:</label>
          <input
            type="text"
            value={telefono}
            onChange={(e) => {
              const value = e.target.value;
              // Solo permite números y máximo 10 dígitos
              if (/^\d*$/.test(value) && value.length <= 10) {
                setTelefono(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el teléfono del propietario ej. 3154824006"  // Mensaje antes de ingresar información
          />
        </div>




        <div className="group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())} // Convierte automáticamente a minúsculas
            required
            className="input-field"
            placeholder="Ingresa el email del propietario ej. parquearqr@gmail.com" // Mensaje antes de ingresar información
          />
        </div>


        <div className="group">
          <label>Torre:</label>
          <input
            type="text"
            value={torre}
            onChange={(e) => setTorre(e.target.value)}
            required
            className="input-field"
            placeholder="Ingresa una torre valida para el conjunto. ej. 01 o A o C1 "  // Mensaje antes de ingresar información
          />
        </div>
        <div className="group">
          <label>Apartamento:</label>
          <input
            type="text"
            value={apto}
            onChange={(e) => setApto(e.target.value)}
            required
            className="input-field"
            placeholder="Ingresa un apto válido para el conjunto ej. 809 ó 402 ó 301B"  // Mensaje antes de ingresar información
          />
        </div>

            {/* Mostrar el mensaje de respuesta del backend */}
            {message && <p className="message-label">{message}</p>}

        <button type="submit" className="btn-submit">
          Agregar Propietario
        </button>
      </form>
    </div>
  );
};

export default PropietariosVehiculos;


