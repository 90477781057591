import React, { useState } from 'react';
import '../App.css'; // Asegúrate de importar los estilos globales

const InsertarVigilantes = () => {
  const [documentoId, setDocumentoId] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nit = localStorage.getItem('nit'); // Usar nit desde localStorage


    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_GUARD_ENDPOINT}`, {

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          documentoId,
          nombre,
          apellidos,
          telefono,
          email,
          nit,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);

        if (data.message === 'VIGILANTE REGISTRADO EXITOSAMENTE') {
          setDocumentoId('');
          setNombre('');
          setApellidos('');
          setTelefono('');
          setEmail('');
        }
      } else {
        setMessage(data.message || 'ERROR DESCONOCIDO');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('ERROR INESPERADO AL REGISTRAR EL VIGILANTE');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="form-left">
        <h2>REGISTRO DE VIGILANTES</h2>

        <div className="group">
          <label>Documento ID:</label>
          <input
            type="text"
            value={documentoId}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && value.length <= 10) {
                setDocumentoId(value);
                setMessage('ESTADO: Digitando información...');
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el documento del vigilante ej. 100254875"
          />
        </div>

        <div className="group">
          <label>Nombre:</label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              if (/^[A-Z\s]*$/.test(value)) {
                setNombre(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el nombre del vigilante ej. JUAN"
          />
        </div>

        <div className="group">
          <label>Apellidos:</label>
          <input
            type="text"
            value={apellidos}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              if (/^[A-Z\s]*$/.test(value)) {
                setApellidos(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa los apellidos del vigilante ej. PEREZ GARCIA"
          />
        </div>

        <div className="group">
          <label>Teléfono:</label>
          <input
            type="text"
            value={telefono}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && value.length <= 10) {
                setTelefono(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa el teléfono del vigilante ej. 3154824006"
          />
        </div>

        <div className="group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            required
            className="input-field"
            placeholder="Ingresa el email del vigilante ej. seguridad@gmail.com"
          />
        </div>

        {message && <p className="message-label">{message}</p>}

        <button type="submit" className="btn-submit">
          Agregar Vigilante
        </button>
      </form>
    </div>
  );
};

export default InsertarVigilantes;
