import React, { useState, useEffect } from 'react';
import './VehiculosContainer.css';
import editIcon from '../assets/editar.png';
import cancelIcon from '../assets/cancelar.png';
import saveIcon from '../assets/Guardar.png';
import useAuth from '../hooks/useAuth';

const GuardsReport = () => {
  const [vigilantes, setVigilantes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [filters, setFilters] = useState({
    cedula: '',
    nombres: '',
    apellidos: '',
    telefono: '',
    email: '',
  });

  const filteredVigilantes = vigilantes.filter((vigilante) => {
    return (
      (vigilante.DOCUMENTO_IDENTIDAD || '').toLowerCase().includes(filters.cedula.toLowerCase()) &&
      (vigilante.NOMBRE || '').toLowerCase().includes(filters.nombres.toLowerCase()) &&
      (vigilante.APELLIDOS || '').toLowerCase().includes(filters.apellidos.toLowerCase()) &&
      (vigilante.TELEFONO || '').toLowerCase().includes(filters.telefono.toLowerCase()) &&
      (vigilante.EMAIL || '').toLowerCase().includes(filters.email.toLowerCase())
    );
  });


  useEffect(() => {
    const nitFromStorage = localStorage.getItem('nit'); // Usar nit desde localStorage
    const fetchVigilantes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_VIGILANTES_ENDPOINT}/${nitFromStorage}`);

        const data = await response.json();
        if (response.ok) {
          setVigilantes(data);
        }
      } catch (error) {
        console.error('Error al cargar los vigilantes', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVigilantes();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
    setEditedData({
      DOCUMENTO_IDENTIDAD: filteredVigilantes[index].DOCUMENTO_IDENTIDAD || '',
      NOMBRE: filteredVigilantes[index].NOMBRE || '',
      APELLIDOS: filteredVigilantes[index].APELLIDOS || '',
      TELEFONO: filteredVigilantes[index].TELEFONO || '',
      email: filteredVigilantes[index].email || '',
    });
  };

  const handleSave = async () => {
    const dataToSend = {
      documento: editedData.DOCUMENTO_IDENTIDAD,
      nombres: editedData.NOMBRE,
      apellidos: editedData.APELLIDOS,
      telefono: editedData.TELEFONO,
      email: editedData.email,
    };

    if (!dataToSend.documento || !dataToSend.nombres || !dataToSend.apellidos || !dataToSend.telefono || !dataToSend.email) {
      alert('Por favor, completa todos los campos obligatorios.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/edit-guard`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const updatedData = [...vigilantes];
        updatedData[editRowIndex] = { ...editedData };
        setVigilantes(updatedData);
        setEditRowIndex(null);
        setEditedData({});
      } else {
        alert('Hubo un error al actualizar el vigilante.');
      }
    } catch (error) {
      console.error('Error en la actualización:', error);
      alert('Error en la conexión al servidor.');
    }
  };

  const handleCancel = () => {
    setEditRowIndex(null);
    setEditedData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  return (
    <div>
      <h2>Listado de Vigilantes</h2>

      <div className="filters">
        {Object.keys(filters).map((filter) => (
          <input
            key={filter}
            className="input-field"
            type="text"
            name={filter}
            placeholder={`Filtrar por ${filter}`}
            value={filters[filter]}
            onChange={handleFilterChange}
          />
        ))}
      </div>

      {loading ? (
        <p>Cargando vigilantes...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>CEDULA</th>
              <th>NOMBRES</th>
              <th>APELLIDOS</th>
              <th>TELEFONO</th>
              <th>EMAIL</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {filteredVigilantes.map((vigilante, index) => (
              <tr key={index}>
                {editRowIndex === index ? (
                  <>
                    <td>{vigilante.DOCUMENTO_IDENTIDAD}</td>
                    <td>
                      <input
                        type="text"
                        name="NOMBRE"
                        value={editedData.NOMBRE || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="APELLIDOS"
                        value={editedData.APELLIDOS || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="TELEFONO"
                        value={editedData.TELEFONO || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="email"
                        value={editedData.EMAIL || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <img
                        src={saveIcon}
                        alt="Guardar"
                        className="icon-button"
                        onClick={handleSave}
                      />
                      <img
                        src={cancelIcon}
                        alt="Cancelar"
                        className="icon-button"
                        onClick={handleCancel}
                      />
                    </td>
                  </>
                ) : (
                  <>
                    <td>{vigilante.DOCUMENTO_IDENTIDAD}</td>
                    <td>{vigilante.NOMBRE}</td>
                    <td>{vigilante.APELLIDOS}</td>
                    <td>{vigilante.TELEFONO}</td>
                    <td>{vigilante.EMAIL}</td>
                    <td>
                      <img
                        src={editIcon}
                        alt="Editar"
                        className="icon-button"
                        onClick={() => handleEdit(index)}
                      />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GuardsReport;